<template>
<div class="dashboard-view">
  <div class="page-view">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="page-title">Dashboards</span>
      </div>
      <div class="text-item">
        <div class="filter_wrapper">
          <div class="filter-dashboards">
            <el-date-picker type="date" placeholder="Pick a date" style="width: 320px"
                            v-model="date"
                            @input="filterDatepicker"
                            :picker-options="pickerOptions"

            ></el-date-picker>
            <el-button
                style="margin-left: 20px"
                :class="type == 0 ? 'bg-blue' : '' "
                @click="filterType(0)"
            >Day</el-button>
            <el-button  :class="type == 1 ? 'bg-blue' : '' "
                        @click="filterType(1)">Month</el-button>
            <el-button  :class="type == 2 ? 'bg-blue' : '' "
                        @click="filterType(2)">Years</el-button>
          </div>
          <el-button type="success" round :disabled="loading">
            <download-excel
                :data="dashboards"
                :fields="fetch_data"
                :name="excelName"
            >
              Excel
            </download-excel>

          </el-button>

        </div>

        <div class="dashboards-table" style="margin-top: 20px">
          <el-table
              v-loading="loading"
              :data="dashboards"
              border
              style="width: 100%">
            <el-table-column
                prop="userName"
                label="Name"
                >
            </el-table-column>
            <el-table-column
                prop="supplierCode"
                label="SupplierName"
            >
              <template slot-scope="scope">{{suppliersName(scope.row.supplierCode)}}</template>

            </el-table-column>
             <el-table-column
                prop="supplierCode"
                label="SupplierCode"
                >
            </el-table-column>
            <el-table-column
                prop="amount"
                label="Amount"
                class="money"
            >
              <template slot-scope="scope" style="text-align: right"><p class="money">{{scope.row.amount | financialFormatMoney}}</p></template>
            </el-table-column>
            <el-table-column
                prop="count"
                label="Count">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
  </div>
</div>
</template>

<script>
import Moment from 'moment'
export default {
  name: "dashboard",
  data(){
    return{
      type: 0,
      date: new Date(),
      dashboards:[],
      loading:false,
      suppliers:[],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      fetch_data: null
    }
  },
  methods:{
    filterType(number){
      this.type = number;
      this.getDashboards()
    },
    filterDatepicker(){
      this.getDashboards()
    },
    async getDashboards(){
      this.loading = true
      let params = {
        date: this.date ? Moment(this.date).format('YYYY-MM-DD') : null,
        cycleType: this.type,
      }
      try {
        const res = await this.$http.get('Admin/GetUserStatistics', {params: params});
        this.dashboards = res.result;
      } catch (e) {
        console.log(e)
        this.$notify.error({
          title: 'Error',
          message: e.response.data.error.message
        });
      } finally {
        this.loading= false;
        let fetch_data = {
          "Name": 'userName',
          "SupplierCode":'supplierCode',
          "SupplierName": {
            field:'supplierCode',
            callback:(value) => {
              return `${this.suppliersName(value)}`
            }
          },
          "Amount" :{
            field: 'amount',
            callback:(value)=>{
              return `${this.$options.filters.financialFormatMoney(value)}`
            }
          },
          "Count":'count'

        }
        this.fetch_data = fetch_data
      }
    },
    getSuppliers(){
      this.$http.post('Admin/getSuppliers')
      .then(response =>{
        this.suppliers = response.result.suppliers1;
      })
    },
    suppliersName(number){
      let item = this.suppliers.find(x => x.code === number)
      return item ? item.name : null
    }
  },
  created() {
    this.getDashboards();
    this.getSuppliers();
  },
  computed:{
    excelName(){
      let type = null
      if (this.type ===0){
        type = 'Day'
      }
      else if (this.type ===1){
        type = 'Month'
      }
      else{
        type = 'Years'
      }
      return `getTransactions ${Moment(this.date).format('YYYY-MM-DD')} ${type}`
    }
  }
}
</script>

<style scoped>
.bg-blue{
  background-color: #409EFF;
  color: white;
}
.money{
  text-align: right !important;
  width: 100%;
}
.filter_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
